@use 'styles/globals/breakpoints';

.block{
    margin: 0;
    width: 20%;

    @media screen and (max-width: breakpoints.$screen-xl) {
        width: 33%;
    }
}




