@use 'styles/globals/mixins/font';
@use "styles/globals/placeholders" as *;


.block{
    width: 100%;
    height: 100%;
    margin: 6rem 0 0 0;
    position: relative;
}



.table{
    width: 100%;
    position: relative;
}

.row{
    display: flex;
    flex-direction: column;
}

.head{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.body{
    @extend %zebraTable;
}

.headingKey{
    width: 10%;
    position: sticky;
    top: 0;
    background-color: var(--white);
}

.key{
    border-bottom: 1px solid #eee;
    font-weight: 800;
    line-height: 1.2;
    text-transform: capitalize;
    padding: 2rem 0 2rem 1rem;
    width: 10%;
}

.podCntr{
    position: relative;
    width: 100%;
    height: auto;
    // border-left: 1px solid #eee;
    display: flex;
    flex-direction: row;
}

.toggle{
    position: absolute;
    top: 550px;
    left: -1px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 4rem 4rem 4rem 2rem;
    background-color: var(--white);
    z-index: 11;
}

.toggleLabel{
    display: flex;
    align-content: center;
    align-items: center;
}

.toggleBottom{
    & .icon{
        transform: rotate(-180deg);
    }
}

.icon{
    transition-timing-function: cubic-bezier(1,-0.03,.23,1);
    transition: all 250ms;
    transform: rotate(-90deg);
}

.sticky{
    position: sticky;
    top: 0px;
    background-color: green;
    width: 100%;
    padding: 1rem;
    z-index: 999;
}

.staticPod{
    max-width: 25%;
    background-color: var(--white);
    overflow: visible;
    pointer-events: auto;
    position: sticky;
    top: 0;
    // box-shadow: var(--box-shadow);
}


.animatedPod{
    @extend .staticPod;
    position: absolute;
    transform-origin: 50% 50% 0px;
}

.placeholderPod{
    @extend .staticPod;
    width: 25%;
    background-color: var(--mist);
    border-right-color: transparent;
}


// table
.tableCntr{
    position: absolute;
    top: 0;
    width: auto;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    height: 100%;

    &::before{
        content: "";
        flex: auto;
    }
}



