@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.body{
    width: 50vw;
    background-color: #fff;
    border-radius: var(--border-radius);
    position: relative;
    padding: 0  2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow-lg);
    overflow-x: auto;
    max-height: 90vh;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0 20px 20px 20px;
        max-height: 90vh;
        overflow: auto;
    }

    &_xsm {
        width: 25vw;

        @media screen and (max-width: breakpoints.$screen-md) {
            width: 95vw;
        }
    }

    &_sm{
        width: 40vw;

        @media screen and (max-width: breakpoints.$screen-md){
            width: 95vw;
        }
    }

    &_md{
        width: 50vw;

        @media screen and (max-width: breakpoints.$screen-md){
            width: 95vw;
        }
    }

    &_lg{
        width: 90vw;

        @media screen and (max-width: breakpoints.$screen-md){
            width: 95vw;
        }
    }
}

.padTop{
    padding-top: 4rem !important;
}

.underlay{
    @extend %modal-underlay
}

.heading{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid var(--dove);

    @media screen and (max-width: breakpoints.$screen-md){
        flex-direction: column;
    }
}

.header{
    @extend %h2;
    @include font.dynamic-font(18, 33);
    margin: 0 !important;
    padding: 0;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0 0 1rem 0;
        order: 2;
        text-align: center;
    }
}

.iconDone{
    margin-right: 1rem;
    fill: var(--green);
}

.closeBtn{
    padding: 2rem 0 1rem 2rem;
    flex: 1 0 auto;
    width: auto;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    background-color: transparent;
    color: var(--ocean);
    @include font.dynamic-font(18, 24);
    line-height: 1;

    & svg{
        fill: var(--ocean);
    }

    @media screen and (max-width: breakpoints.$screen-md){
        order: 0;
        width: 100%;
        padding: 1rem 0 1rem 1rem;
    }
}

.customCloseIcon{
    transform: rotate(180deg);
    margin-left: .5rem;
    margin-top: 4px;
}



@keyframes remodal-overlay-opening-keyframes {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes remodal-overlay-closing-keyframes {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
