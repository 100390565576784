
.textBlock{
    padding: 2rem;

    & p{
        &:last-of-type{
            margin: 0
        }
    }
}

.fullImage img {
    width: 100%;
}
