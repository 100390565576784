@use 'styles/globals/breakpoints';

.block{
    display: flex;
    flex-wrap: wrap;
    margin-left: -2rem;
    padding: 2rem;
    width: 50%;
}

.address{
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    position: relative;
}


.container{
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    padding: 4rem;
    flex: 1 0 auto;
    box-shadow: var(--box-shadow);
}

.toggleBtn{
    line-height: 1;
    background-color: transparent;
    position: absolute;
}


.defaultButton{
    padding: 10px;
    max-width: 190px;
    margin-right: 20px;
}


.buttonRow{
    display: flex;
}


@media (max-width: breakpoints.$screen-sm) {
    .container{

        padding: 2rem;
    }

    .address{
        width: 100%;
        padding: 0px 0px 0px 20px;

        fieldset > button{
            margin-right: 10px;
            width: 140px !important;
        }
    }
}

