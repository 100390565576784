
@use 'styles/globals/breakpoints';

.block{
    display: inline-block !important;
    padding: 4rem 3rem;
    width: 100% !important;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 4rem 2rem;
        width: 100vw;
    }

    &.noArrows {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.block figcaption {
    text-align: center;
}

.explorationRowTitle{
    font: normal normal normal 54rem Didot Display;
    margin-bottom: 0 !important;
}

