
.cardImageWrapper {
    width: 400px;
    //padding: 0 2rem;
    box-sizing: border-box;
}

.enlargedImage {
    height: calc(360 * 1.5) !important;
    object-fit: cover;
}

.titlePadding {
    padding-top: 1rem;
}

.cardBodyPadding {
    padding-bottom: 3rem;
}

