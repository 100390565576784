@use "styles/globals/placeholders" as *;

.block{
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    padding: 0 4vw 10rem 4vw;
    overflow: auto;
}

.headingKey{
    width: 10%;
    position: sticky;
    top: 0;
    background-color: var(--white);
}

.header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 0 0 auto;
    align-items: center;
    padding: 6rem 0
}

.body{
    @extend %zebraTable;
}

.heading{
    line-height: 1;
    text-align: center;
    width: 100%;
}

.close{
    position: absolute;
    height: auto;
    background-color: transparent;
    top: 10px;
    left: 10px;
}

.ctaCell{
    padding: 2rem 1rem;

    & *{
        width: 100%;
    } 
}

