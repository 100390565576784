/*!
 * Bootstrap Utilities v5.0.0-beta3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// Configuration
@use './breakpoints';
@use './placeholders' as *;
@use 'styles/globals/mixins/font';

@import "./functions";
@import './util-variables';
@import "./mixins";
@import "./utilities";

// Helpers
// @import "./helpers";

// Utilities
@import "./utilities/api";


.container {
    max-width: var(--standard-width);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    padding: 0 2vw
}

// VDS Utils Typography 
.fs_1,
.h1 {
    @extend .fs_1;
    @extend %h1;
    @include font.dynamic-font(30, 44);

    @media print{
        font-size: 2.1rem !important;
    }
}

.fs_2,
.h2 {
    @extend .fs_2;
    @extend %h2;
    @include font.dynamic-font(20, 25);

    @media print{
        font-size: 1.8rem !important;
    }
}

.fs_3,
.h3 {
    @extend .fs_3;
    @extend %h3;
    @include font.dynamic-font(18, 23);

    @media print{
        font-size: 1.6rem !important;
    }
}

.fs_4,
.h4 {
    @extend .fs_4;
    @extend %h4;
    @include font.dynamic-font(16, 20);

    @media print{
        font-size: 1.2rem !important;
    }
}

.fs_5,
.h5 {
    @extend .fs_5;
    @extend %h5;
    @include font.dynamic-font(14, 18);
}

.fs_6,
.h6 {
    @extend .fs_6;
    @extend %h6;
    @include font.dynamic-font(12, 16);
}


.quote {
    @extend %h3;
    @include font.dynamic-font(16, 18);
    letter-spacing: 0.4rem;
    text-transform: none;
    font-weight: normal;

    & > strong {
        font-weight: normal;
        color: var(--ocean);
    }
}


.cta {
    color: var(--ocean);
    border-bottom: 1px solid var(--ocean);
    position: relative;
    font-size: 1.8rem !important;
    letter-spacing: 0.05rem !important;

    &::after {
        @extend %dark-arrow;
        border-left-color: var(--ocean);
        transition: 200ms all ease;
    }

    &:hover {
        text-decoration: none;
        border-bottom: 1px solid var(--indigo);
        color: var(--indigo);

        &::after {
            border-left-color: var(--indigo);
            // right: -20px;
            transform: translateX(5px);
        }
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        font-size: 2rem;
    }
}

.cta_2 {
    @extend .cta;
    @extend .fs_5;

    @media only screen and (max-width: breakpoints.$screen-md) {
        font-size: 2rem;
    }
}

.cta_3 {
    @extend .fs_5;
    border-bottom: 1px solid var(--link_color);
    align-self: baseline;
    color: var(--link_color);
    font-weight: 600;

    &:hover {
        text-decoration: none;
        border-bottom: 1px solid var(--link_color);
        color: var(--link_color);
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        font-size: 1.9rem;
    }
}

.flex_wrap {
    flex-wrap: wrap !important;
}

.list_styled {
    @extend %unordered-list;
}

.list_unstyled {
    list-style-type: none;
}

.order_0 {
    order: 0;
}

.order_2 {
    order: 2;
}


.w_10 {
    width: 10% !important;
}

.w_25 {
    width: 25% !important;
}

.w_34 {
    width: 34% !important;
}

.w_50 {
    width: 50% !important;
}

.w_66 {
    width: 66% !important;
}

.w_75 {
    width: 75% !important;
}

.w_100 {
    width: 100% !important;
}