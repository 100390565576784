@use 'styles/globals/mixins/font';


.block{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: space-between;
    position: relative;
    // cursor: grab !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
        -ms-user-select: none; 
            user-select: none;

    &:hover{
        & .buttonBlock{
            opacity: 1;
        }
    }
}

.link{
    width: 100%;
}

.image{
    width: 100%;
    object-fit: contain;
    height: auto;
    max-width: 200px;
    max-height: 200px;
}

.valueCell{
    padding: 2rem;
    border-bottom: 1px solid #eee;
    line-height: 1.2;
}

.value{
    line-height: 1.2;
    @include font.dynamic-font(13, 16);
}


.product{
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    position: relative;
    border-right: 1px solid #eee;
    height: 100%;
}

.heading{
    flex-direction: column;
    display: flex;
    inset: 0 0 0 0;
    padding: 0 2rem 1rem 2rem;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;
}

.buttonBlock{
    width: 100%;
    position: absolute;
    z-index: 9;
    padding: 0 2rem;
    display: flex;
    align-content: center;
    justify-content: space-between;
    opacity: 0;
    transition-timing-function: cubic-bezier(1,-0.03,.23,1);
    transition: all 250ms;
}

.dragBtn{
    background-color: transparent;
    padding: 0 1rem;
    font-size: 3rem;
    width: 75%;
    text-align: left;
    line-height: 1;
}

.closeBtn{
    background-color: transparent;
}

.titleBlock{
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 1rem 0;
    height: auto;
    // min-height: 100px;
}

.brandName{
    font-weight: 400;
}

.titleLink{
    text-align: left;
    color: inherit;
    margin: 0;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.2;

    &:hover{
        color: inherit;
        text-decoration: none;
    }
}

.brandLInk{
    font-size: 1.4rem;
    margin: 0;
}

.priceCntr{
    display: flex;
    width: 100%;
    align-self: flex-end;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-end;
}

.price{
    flex: 1 0 auto;
    text-align: left;
}


