

.video{
    width: 100%; 
    height: 100%; 
    object-fit: fill;
    object-position: left center;
    max-width: var(--standard-width);
}

