@use 'styles/globals/breakpoints';


.questionCol{
    width: 100%;
    z-index: 1;

    @media only screen and (max-width: breakpoints.$screen-sm) {
        padding-right: 0;
    }
     
 }


.questionItem{
    padding: 3rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    border-radius: var(--border-radius);
    position: relative;
    box-shadow: var(--box-shadow);

    &::after{
        content: "";
        box-shadow: var(--box-shadow-lg);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &:hover::after{
        opacity: 1;
    }

    &:last-of-type{
        border: none;
        margin: 0;
    }
}

.questionHeading{
    display: flex;
    flex-direction: column;
    margin: 0px;
}

.questionText{
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: 800;
    display: flex;
    align-items: flex-start;
    align-content: center;
    margin-bottom: 3px;
    text-align: left;
}

.questionAnswerCount{
    // @extend .product__swatchOptionIconCntr;
    align-items: center;
    align-content: center;
    color: var(--ocean);
}

.questionMeta{
    font-size: 1.4rem;
    color: #aaa;
    font-style: italic;
    font-weight: 400;
    align-self: flex-start;
}



// answers
.answer{
    display: flex;
    flex-direction: column;
    border-left: 5px solid rgba(110, 164, 56, 0.15);
    padding: 0 1rem 0 2rem;
    margin: 2rem 0 0 0;
    align-items: flex-start;
    font-size: inherit;
    font-weight: inherit;
}

.answerText{
    line-height: 1.4;
    text-align: left;
}

.answerMeta{
    font-size: 1.4rem;
    color: #aaa;
    font-style: italic;
}
