@use 'styles/globals/breakpoints';


.block{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    margin: 0 0 3rem 0;
    justify-content: space-between;
    padding-top: 4rem;
    padding-left: 1rem;

    &::after {
        content: "";
        width: auto;
        flex: 1 0 auto;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        & [class*="Swatch_popover"]{
            display: none;
        }
    }
}

.blur{
    filter: blur(8px);
}

.modalCntr{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.cart{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ccc;
    padding: 2rem 2rem 0 2rem;
    border-radius: var(--border-radius);
    position: sticky;
    top: 140px;
    box-shadow: var(--box-shadow);
    margin-top: 4rem;
}

.container{
    margin: 2rem 0;
    list-style: none;
    display: flex;
    flex-direction: row;
}

.selectedSwatch{
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    width: 33.3%;
    align-items: center;

    &:last-of-type{
        margin: 0;
    }

    & img{
        border-radius: var(--border-radius);
        width: 100%;
        object-fit: fill;
        height: auto;
    }
}


.removeIcon{
    position: absolute;
    top: -1px;
    right: 0;
}

.removeBtn{
    background-color: transparent;
    position: relative;
    width: auto;
    height: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label{
    margin-top: .5rem;
    font-size: 1rem;
}

.iconArrow{
    fill: var(--white);
    stroke: var(--white);
    height: 10px !important;
    margin-left: 1rem !important;
}

.refProduct{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
}

.refProductName{
    margin-left: 2rem;
    line-height: 1.2;
}


// checkout

.column{
    margin-top: 4rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin: 0;
        padding: 2rem;
    }
}

.swatch{
    width: 200px;
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100px;
    }
}

.imgCntr{
    height: 500px;
    overflow: hidden;
}

.image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swatchImage{
    width: 100%;
    object-fit: contain;
}

// confirmation

.confColumn{
    padding: 0 2rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin: 0;
        padding: 2rem;

        &:last-of-type{
            padding: 2rem;
        }
    }
}

.continueBtn{
    background-color: transparent;
    color: var(--link_color);
}
