@use './breakpoints';
@use 'styles/globals/mixins/font';


%optionStep{
    background-color: var(--ocean);
    color: var(--white) !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    line-height: 1;
    min-width: 40px;
    height: 40px;
    border-radius: 99em;
    margin: 0 2rem 0 0;
    padding-left: 4px;
}


%atcBtnBlock{
    margin-left: 2rem;
    position: relative;
    overflow: hidden;
    max-width: 500px;
    height: 55px;

    &:focus{
        outline: 0;
    }

    &:before{
        content: '';
        display: block;
        position: absolute;
        background: rgba(259,259,259,0.75);
        width: 60px;
        height: 100%;
        left: 0;
        top: 0;
        opacity: .5;
        filter: blur(30px);
        transform: translateX(-100px)  skewX(-15deg);
    }

    &:after{
        content: '';
        display: block;
        position: absolute;
        width: 50px;
        height: 100%;
        left: 50px;
        top: 0;
        opacity: 0;
        filter: blur(5px);
        transform: translateX(-100px) skewX(-15deg);
    }
}


%popover_arrow{
    position: absolute;
    width: 15px;
    height: 15px;
    background: inherit;
    visibility: hidden;

    &::before {
        position: absolute;
        width: 15px;
        height: 15px;
        background: inherit;
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }
}

%popover{
    z-index: 9;    
    padding: 2rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lg);
    z-index: 99;

    &[data-popper-placement^='top'] > .arrow {
        bottom: -4px;
    }
    
    &[data-popper-placement^='bottom']{
        box-shadow: 0 -.5rem 1rem rgba(#000, .15);

        & > .arrow {
            top: -4px;
        }
    }

    &[data-popper-placement^='left'] > .arrow {
        right: -4px;
    }
    
    &[data-popper-placement^='right'] > .arrow {
        left: -4px;
    }
}


%options_block{
    padding: 1rem 2rem 0 2rem;
    margin: 2rem 0 3rem 0;
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-sm);
    overflow: hidden;
    width: 100%;
}


%shipping_heading{
    margin: 0;
    line-height: 1.3;
    @include font.dynamic-font(16, 18);
    font-weight: 800;
}

%shipping_icon{
    flex: 1 0 60px;
    max-width: 60px;
}


// Placeholder Selectors
%max-flyout-height{
    // height: 600px !important;
    height: 500px !important;
    transition: var(--easeInOutBack);

    @media screen and (max-width: breakpoints.$screen-sm){
        height: auto !important;
    }
}

%min-flyout-height{
    height: 450px !important;
    transition: var(--easeInOutBack);

    @media screen and (max-width: breakpoints.$screen-sm){
        height: auto !important;
    }
}

%wee-flyout-height{
    height: 375px !important;
    transition: var(--easeInOutBack);

    @media screen and (max-width: breakpoints.$screen-sm){
        height: auto !important;
    }
}

%standard-button{
    border-width: 1px;
    border-style: solid;
    padding: 1.7rem calc(1vw + 17px);
    text-transform: capitalize;    
    border-radius: var(--button-border-radius);
    text-decoration: none !important;
    border-color: transparent;
    background-color: var(--charcoal);
    color: var(--white);
    display: inline-flex;
    font-weight: normal;
    @include font.dynamic-font(18, 20);
    font-size: 2.4rem;
    line-height: 1;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    max-height: 60px;
}

%standard-control{
    display: block;
    flex: 1 0 auto;
    width: 100%;
    height: 100%;
    padding: 1.375rem 2rem 1.375rem 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--charcoal);
    -webkit-tap-highlight-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid var(--ocean);
    background-color: #fff;
    background-clip: padding-box;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    outline-color: 5px solid var(--dove) !important;
}

%standard-active-control{
    & label{
        top: -8px;
        width: 99.5%;
        font-size: 1.3rem;
        color: var(--charcoal);
    }
}




%standard-control-label{
    height: auto;
    position: absolute;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 80%;
    line-height: 1.2;
    background-color: #fff;
    top: 18px;
    left: 1px;
    padding: 0 0 0 1rem;
    z-index: 1;
    color: var(--ocean);
    transition: all 0.2s ease;

    @media screen and (max-width: breakpoints.$screen-md){
        width: 85%;
    }

    &:hover{
        cursor: pointer;
    }
}

%standard-control-help-text{
    padding: 1rem 0 0 .5rem;
    width: 100%;
    line-height: 1.3;
    font-size: 1.3rem;
}


%flex-full-width{
    display: flex;
    width: 100vw;
}


%optionsBlock{
    padding: 1rem 2rem 0 2rem;
    margin: 4rem 0 4rem 0;
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-sm);
}

%truncate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

%badge{
    display: inline-block;
    padding: .25rem .5rem;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
}

%scrolling-inner{
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--mist);
    -webkit-overflow-scrolling: touch;


    &::-webkit-scrollbar {
        width: 8px;
        border-radius: var(--border-radius);
        cursor: pointer;
    }

    &::-webkit-scrollbar-track{
        background: var(--mist);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #11111150; 
        border-radius: var(--border-radius);
    }
}

%button-animate{
    transition: all .1s cubic-bezier(.59,.28,.83,.67);

    &:hover{
        transform: translate(-1px, -2px);
        box-shadow: var(--box-shadow);
        filter: brightness(1.25);
    }

    &:active, &:focus{
        transform: translate(0px, 1px);
    }
}



%search-cancel-button{
    &::-webkit-search-cancel-button {
        height: 25px;
        width: 25px;
        display: none;
        opacity: 1;
        margin: 0;
        color: #777777;
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23777777' viewBox='0 0 24 24'%3E%3Cpath d='M13.4 12l5.3-5.3c0.4-0.4 0.4-1 0-1.4s-1-0.4-1.4 0l-5.3 5.3-5.3-5.3c-0.4-0.4-1-0.4-1.4 0s-0.4 1 0 1.4l5.3 5.3-5.3 5.3c-0.4 0.4-0.4 1 0 1.4 0.2 0.2 0.4 0.3 0.7 0.3s0.5-0.1 0.7-0.3l5.3-5.3 5.3 5.3c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4l-5.3-5.3z'%3E%3C/path%3E%3C/svg%3E");
    }
}

%freeze-body{
    overflow: hidden;
    position: relative;
    max-height: 100vh;
}

%dark-arrow{
    content: "";
    width: 0px; 
    height: 0px; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent; 
    border-left: 8px solid var(--charcoal); 
    position: absolute;
    right: -15px;
    top: 50%;
    margin-top: -6px;
}

// VDS Utils Typography 
%h1{
    font-family: var(--font-stack-serif) !important;
    font-kerning: none;
    text-transform: capitalize;
    font-weight: normal;
    letter-spacing: 0.528rem;
    line-height: 1.2;
    margin-bottom: 3rem;
    color: var(--indigo);

    @media only screen and (max-width: breakpoints.$screen-sm) {
        letter-spacing: 0.36rem;
    }
}

%h2{
    font-family: var(--font-stack) !important;
    line-height: 1.2;
    font-kerning: none;
    letter-spacing: 0.3rem;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 3rem;
    color: var(--indigo);
    
    @media only screen and (max-width: breakpoints.$screen-sm) {
        letter-spacing: 0.24rem;
    }
}

%h3{
    font-family: var(--font-stack) !important;
    line-height: 1.2;
    margin-bottom: 3rem;
    font-kerning: none;
    letter-spacing: 0.276rem;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--indigo);

    @media only screen and (max-width: breakpoints.$screen-sm) {
        letter-spacing: 0.216rem;
    }
}

%h4{
    font-family: var(--font-stack) !important;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-kerning: none;
    letter-spacing: 0.24rem;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--indigo);

    @media only screen and (max-width: breakpoints.$screen-sm) {
        letter-spacing: 0.192rem;
    }
}

%h5{
    font-family: var(--font-stack) !important;
    line-height: 1.2;
    margin-bottom: 1rem;
    font-kerning: none;
    letter-spacing: 0.216rem;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--indigo);

    @media only screen and (max-width: breakpoints.$screen-sm) {
        letter-spacing: 0.168rem;
    }
}

%h6{
    font-family: var(--font-stack) !important;
    font-kerning: none;
    letter-spacing: normal;
    line-height: 1.4;
    margin-bottom: 1rem;
    font-weight: 600;
    color: var(--indigo);
}


%ul{
    position: relative;
    margin: 0 0 0 4rem;

    &::before{
        content: "";
        position: absolute;
        height: 100%;
        border-left: 5px solid var(--ice);
        left: -4rem;
    }
}


%unordered-list{
    list-style: none;

    & li{
        margin-bottom: 2.1rem;
    }

    & > ul{
        @extend %ul
    }
}




%ordered-list{
    list-style: none;
    
    & li{
        margin-bottom: 2.1rem;
    }

    & > ol {
        padding-left: 4rem;
        margin: 0 0 0 6rem;
        counter-reset: section;

        & li{
            position: relative;
            counter-increment: step-counter;
            padding: 3rem 0 3rem 1rem;
            margin: 0;

            &::before{
                content: "";
                background-color: var(--ocean);
                width: 70px;
                height: 70px;
                border-radius: 9rem;
                position: absolute;
                top: 50%;
                margin-top: calc(-70px/2);
                left: -8rem;
                z-index: 0;
                content: counter(step-counter);
                font-size: 6rem;
                color: var(--white);
                font-family: var(--font-stack-serif);
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
            }

            &::marker{
                content: "";
            }
            
        }
    }
}



// VDS UL and OL style
%textBlock{
    margin-bottom: 4rem;

    @extend %unordered-list;
    @extend %ordered-list;

    & p{
        &:last-of-type{
            margin: 0
        }
    }
}


%modal-underlay{
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(50, 50, 50, 0.75);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: 300ms;
    animation-fill-mode: forwards;
    animation-name: remodal-overlay-opening-keyframes;
}



%tableBlock{
    display: table;
    width: 100%;
    table-layout: fixed;

    @media print {
        font-size: 1rem;
    }
}

%tableRow{
    line-height: 1.5;
    border-bottom: 1px solid #ccc;
    display: table-row;

    &:last-of-type{
        border: none
    }
}

%tableHeadingCell{
    display: table-cell;
    font-size: 1.3rem;
    text-transform: uppercase;
}

%tableCell{
    padding: 1rem 0;
    display: table-cell;

    &:first-of-type{
        font-weight: 800;
    }

    @media print {
        padding: 5px 0;
    }
}

%tableCellContent{
    display: flex;
    flex-direction: row;
}

%zebraTable{
    & tr:nth-child(2n){
        background-color: var(--ice25);
    }
}

