@use 'styles/globals/breakpoints';

.block{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 3rem;
    max-width: 350px;

    @media only screen and (max-width: breakpoints.$screen-xxl ) {
        padding: 1rem 1rem 3rem;
        max-width: 280px !important;
    }

    @media only screen and (max-width: breakpoints.$screen-xl ) {
        max-width: 210px !important;
    }


    @media only screen and (max-width: breakpoints.$screen-lg ) {
        max-width: 360px !important;
    }
}

.blockTextAbsent {
    max-width: 440px !important;

    @media only screen and (max-width: breakpoints.$screen-xl ) {
        padding: 1rem 1rem 3rem;
    }

    @media only screen and (max-width: breakpoints.$screen-xl ) {
        max-width: 260px !important;
    }
    @media only screen and (max-width: breakpoints.$screen-lg ) {
        max-width: 420px !important;
    }
}
.noPad{
    padding: 0 !important
}

.title{
    text-align: center;
    width: 100%;
    display: block;
}

.body{
    // padding: 2rem 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    @media only screen and (max-width: breakpoints.$screen-xs ) {
        padding: 1rem 0
    }
}

.text{
    text-align: center;

    & p{
        margin: 0
    }
}


.links{
    width: 100%;
    flex: 1 1 auto;
    padding: 1rem 0 0 0;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;

    & > a{
        text-decoration: underline;
        text-align: center;
    }

    & > a, & > button{
        margin-right: 2rem;

        &:last-of-type{
            margin: 0;
        }
    }
}

.linkArea{
    display: block;

    & .cardText {
        color: #222 !important;
    }

    &:hover{
        text-decoration: none !important;
    }
}



.media{
    display: flex;
    // flex: 1 1 auto;
    //margin: 1rem 0;
    position: relative;
    justify-content: center;


    @media only screen and (max-width: breakpoints.$screen-xs ) {
        height: auto !important;
    }
}

.image{
    object-fit: contain;
    width: 100%;
    height: auto;
}

.video{
    width: 100%;
    object-fit: cover;
}

.align{

    &__left{
        text-align: left !important;
        align-self: flex-start;
        justify-content: flex-start;
    }

    &__center{
        text-align: center !important;
        align-self: center;
    }

    &__right{
        text-align: right !important;
        align-self: flex-end;
        justify-content: flex-end;
    }

    &__vertical{
        flex-direction: column;
        align-content: center;
        text-align: center;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        & > a{
            flex: 1 0 auto;
            padding: 1rem;
            margin: 0 !important;
            width: 100%;
            border-bottom: none;
            text-decoration: underline;

            &:hover{
                border-bottom: none;
            }
        }
    }
}

.imageLink {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
}
